<tui-loader
    [showLoader]="loadingService.States[
                          loadingService.getIdFromArrayStateByType(
                            loadingService.StatesNames.NEW_ARTICLE
                          )
                        ].value"
    [overlay]="true"
>
    <form
            [formGroup]="form"
            (ngSubmit)="SendData()"

    >
        <div class="tui-form__row">
            <label>Название</label>
            <tui-input
                    formControlName="name"
            >
                <input
                        placeholder="Введите название"
                        tuiTextfield
                />
                <span class="tui-required"></span>
            </tui-input>
        </div>
        <div class="tui-form__row">
            <label>Ссылка</label>
            <tui-input
                    formControlName="url"
            >
                <input
                        placeholder="Введите название"
                        tuiTextfield
                />
                <span class="tui-required"></span>
            </tui-input>
            <tui-error
                    formControlName="url"
                    [error]="[] | tuiFieldError | async"
            ></tui-error>
        </div>
        <div class="tui-form__row">
            <label
                    class="form-label"
            >
                Изображение (Рекомендовано: 340px x 200px)
            </label>
            <tui-input-files
                    accept="image/png, image/jpeg"
                    [link]="'Выберите файл'"
                    [label]="' '"
                    [maxFileSize]="5242880"
                    [(ngModel)]="file"
                    [ngModelOptions]="{standalone: true}"
                    (ngModelChange)="AddFile($event)"


            >
                <input
                        tuiInputFiles
                        [accept]="'image/png,image/jpeg'"
                        [multiple]="false"
                />
            </tui-input-files>
            <div class="tui-form__row">
                <img dz-img class="preview_img object-fit" [src]="filePreview" *ngIf="filePreview && file === ''">
                <img class="preview_img object-fit" [src]="filePreview" *ngIf="filePreview && file">
            </div>

        </div>
        <button
                [showLoader]="loadingService.States[
                      loadingService.getIdFromArrayStateByType(
                        loadingService.StatesNames.NEW_ARTICLE
                      )
                    ].value"
                type="submit"
                tuiButton
        >
            {{Id === 0? 'Создать':'Сохранить'}}</button>
    </form>
</tui-loader>
