import { NgModule, inject } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { Page404Component } from './features/page404/page404.component';
export const authGuard = () => {
  const router = inject(Router);
  if (localStorage.getItem('access_token')) {
    return true;
  }
  return router.navigate(['/auth']);
};

export const notAuthGuard = () => {
  const router = inject(Router);
  if (!localStorage.getItem('access_token')) {
    return true;
  }
  return router.navigate(['/base']);
};

const routes: Routes = [
  {path: '', redirectTo: 'auth', pathMatch: 'full'},
  {path: 'auth', canActivate: [notAuthGuard], loadChildren: () => import('./features/auth/auth.module').then(m => m.AuthModule)},
  {path: 'base', canActivate: [authGuard],  loadChildren: () => import('./features/base/base.module').then(m => m.BaseModule)},
  {path: '**', component: Page404Component},
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
