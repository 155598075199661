import { NgDompurifySanitizer } from "@tinkoff/ng-dompurify";
import { TuiRootModule, TuiDialogModule, TuiAlertModule, TUI_SANITIZER, TuiButtonModule, TuiSvgModule, TuiLoaderModule } from "@taiga-ui/core";
import { TuiAvatarModule, TuiCalendarRangeModule, TuiInputModule, TuiInputPasswordModule} from '@taiga-ui/kit';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {PreloaderComponent} from "./preloader/preloader.component";

@NgModule({

  declarations:[
      PreloaderComponent
  ],
  imports: [
        FormsModule,
        ReactiveFormsModule,
        TuiRootModule,
        TuiInputModule,
        TuiInputPasswordModule,
        TuiDialogModule,
        TuiAlertModule,
        TuiButtonModule,
        TuiSvgModule,
        TuiAvatarModule,
        TuiLoaderModule,
        TuiCalendarRangeModule,
    ],
    providers: [
        { provide: TUI_SANITIZER, useClass: NgDompurifySanitizer },
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        TuiRootModule,
        TuiInputModule,
        TuiInputPasswordModule,
        TuiDialogModule,
        TuiAlertModule,
        TuiButtonModule,
        TuiSvgModule,
        TuiAvatarModule,
        TuiLoaderModule,
        TuiCalendarRangeModule,
        PreloaderComponent
    ]
})
export class UiModule { }
