import { Component, Inject } from '@angular/core';
import { TUI_IS_E2E } from '@taiga-ui/cdk';
import {EventsService} from "./core/services/events.service";
import {ToastrService} from "ngx-toastr";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'dzenika-admin-fe';

  private destroy$ = new Subject();

  constructor(
      private events: EventsService,
      private toastr: ToastrService
  ) {
    this.InitToast();
  }
  InitToast() {
    this.events.IsErrorChange$.pipe(takeUntil(this.destroy$)).subscribe(
        (res) => {
          if (res && this.events.Error.status) {
            this.toastr.error(this.events.Error.text, 'Ошибка!', {
              positionClass: 'toast-bottom-center',
              titleClass: 'title-toast',
            });
          } else {
            this.toastr.clear();
          }
        }
    );

    this.events.IsSuccessChange$.pipe(takeUntil(this.destroy$)).subscribe(
        (res) => {
          if (res && this.events.Success.status) {
            this.toastr.success(
                this.events.Success.text,
                'Операция выполнена успешно',
                {
                  positionClass: 'toast-bottom-center',
                  titleClass: 'title-toast',
                }
            );
          } else {
            this.toastr.clear();
          }
        }
    );
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
