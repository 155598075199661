import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {BehaviorSubject, catchError, EMPTY, Observable, of, Subject, Subscription, throwError} from 'rxjs';
import { debounceTime, pluck, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoadingService, LoadingStates } from './loading.service';

@Injectable({
  providedIn: "root",
})
export class MainService {

  ServerURL = `${environment.apiUrl}/v1`;

  constructor(
      private http: HttpClient,
      private router: Router,
      public loadingService: LoadingService
  ) {
  }

  Login(payload:any) {
    return this.RequestHandler(() => {
      return this.http.post(this.ServerURL + "/admin/login", payload);
    }, this.loadingService.StatesNames.LOGIN);
  }

  RequestHandler(
      runRequest: () => Observable<any>,
      RequestType = LoadingStates.UNKNOWN
  ): Observable<any> {
    let loadingId = this.loadingService.addLoading(RequestType);

    return runRequest().pipe(
        debounceTime(50),
        tap(
            (dataRes) => {
              this.loadingService.removeLoading(loadingId);
            },
            (catchError) => {
              this.loadingService.removeLoading(loadingId);
            }
        ),
        catchError((err:HttpErrorResponse)=>{
          return throwError(err);
        })
    );
    
  }

  SendPost(method: string, data:any, type = LoadingStates.UNKNOWN) {
    return this.RequestHandler(() => {
      return this.http.post(this.ServerURL + method, data);
    }, type);
  }


  SendPut(method: string, data:any, type = LoadingStates.UNKNOWN) {
    return this.RequestHandler(() => {
      return this.http.put(this.ServerURL + method, data);
    }, type);
  }

  SendGet(method: string, type = LoadingStates.UNKNOWN) {
    return this.RequestHandler(() => {
      return this.http.get(this.ServerURL + method);
    }, type);
  }

  SendGetText(method: string, type = LoadingStates.UNKNOWN) {
    return this.RequestHandler(() => {
      return this.http
          .get(this.ServerURL + method, {
            responseType: "text",
          })
          .pipe(
              tap((data) => {
                //console.log(data, typeof(data))
              })
          );
    }, type);
  }

  SendDelete(method: string, data:any, type = LoadingStates.UNKNOWN) {
    return this.RequestHandler(() => {
      return this.http.delete(this.ServerURL + method,);
    }, type);
  }

  SendPatch(method: string, data:any, type = LoadingStates.UNKNOWN) {
    return this.RequestHandler(() => {
      return this.http.patch(this.ServerURL + method, data,);
    }, type);
  }

}

