
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { MainInterceptor } from "./core/helpers/main.interceptor";
import { ErrorInterceptor } from "./core/helpers/error.interecepter";
import { Page404Component } from './features/page404/page404.component';
import { UiModule } from "./features/ui/ui.module";
import {ToastrModule} from "ngx-toastr";
import {of} from "rxjs";
import {TUI_LANGUAGE, TUI_RUSSIAN_LANGUAGE} from '@taiga-ui/i18n';
import {TuiErrorModule, TuiRootModule, TuiTextfieldControllerModule} from "@taiga-ui/core";
import {ExampleNativeDateTransformerDirective} from "./core/directives/date-transform.directive";
import {ImageDirective, provideImagePathPrefix} from "./core/directives/image.directive";
import {environment} from "../environments/environment";
import { ArticleNewComponent } from './features/base/articles/article-new/article-new.component';
import {TuiFieldErrorPipeModule, TuiInputFilesModule} from "@taiga-ui/kit";

@NgModule({
  declarations: [
    AppComponent,
    Page404Component,
    ExampleNativeDateTransformerDirective,
    ArticleNewComponent

  ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        TuiRootModule,
        AppRoutingModule,
        UiModule,
        HttpClientModule,
        ToastrModule.forRoot(),
        TuiTextfieldControllerModule,
        TuiInputFilesModule,
        TuiFieldErrorPipeModule,
        TuiErrorModule,
        ImageDirective,
    ],
  providers: [
    HttpClient,
    { provide: HTTP_INTERCEPTORS, useClass: MainInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: TUI_LANGUAGE,
      useValue: of(TUI_RUSSIAN_LANGUAGE),
    },
    provideImagePathPrefix(environment.apiUrl),
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
