import {Component, Inject, OnInit} from '@angular/core';
import {LoadingService} from "../../../../core/services/loading.service";
import {ArticlesService} from "../../../../core/services/articles.service";
import {EventsService} from "../../../../core/services/events.service";
import {ActivatedRoute, Route, Router} from "@angular/router";
import {AbstractControl, FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {DomSanitizer} from "@angular/platform-browser";
import {TUI_VALIDATION_ERRORS} from "@taiga-ui/kit";
import {TuiDialogContext, TuiDialogService} from "@taiga-ui/core";
import {POLYMORPHEUS_CONTEXT} from "@tinkoff/ng-polymorpheus";

@Component({
  selector: 'app-article-new',
  templateUrl: './article-new.component.html',
  styleUrls: ['./article-new.component.scss'],
  providers: [
    {
      provide: TUI_VALIDATION_ERRORS,
      useValue: {
        invalidUrl: 'Неверный формат ссылки'
      },
    },
  ],
})
export class ArticleNewComponent implements OnInit{


  Id = 0;
  file:any = '';
  filePreview:any = '';


  urlValidator: ValidatorFn = (control: AbstractControl) => {
    let validUrl = true;

    try {
      new URL(control.value)
    } catch {
      validUrl = false;
    }

    return validUrl ? null : { invalidUrl: true };
  }



  form = new FormGroup({
    name: new FormControl('', [Validators.required]),
    url: new FormControl('', [Validators.required,this.urlValidator]),
    orderNum: new FormControl(1,[Validators.required]),
  })


  constructor(
      public loadingService: LoadingService,
      private articlesService: ArticlesService,
      private eventsService: EventsService,
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private sanitizer: DomSanitizer,
      @Inject(TuiDialogService) private readonly dialogs: TuiDialogService,
      @Inject(POLYMORPHEUS_CONTEXT)
      private readonly context: TuiDialogContext<any, any>,
  ) {
  }
  ngOnInit(): void {
    this.Id = this.data.id;
    if(this.Id!==0){
      this.form.get('name')?.setValue(this.data.payload.name);
      this.form.get('url')?.setValue(this.data.payload.url);
      this.filePreview = this.data.payload['publicUrl'];
    }
  }

  SendData(){
    for (const i in this.form.controls) {
      this.form.get(i)?.updateValueAndValidity();
    }
    this.form.updateValueAndValidity();
    this.form.markAllAsTouched();
    const valid = this.form.valid;
    if(valid && (this.file || this.filePreview)){
      const form = new FormData();
      for (const [key, value] of Object.entries(this.form.getRawValue())) {
        form.append(key,value?.toString()||'');
      }
      if(this.file){
        form.append('file',this.file);
      }
      if(this.Id === 0){
        this.articlesService.NewArticle(form).subscribe({
          next:()=>{
            this.eventsService.success('Статья успешно создана');
            this.context.completeWith(true)
          },
          error:()=>{
            this.eventsService.throwError('Что-то пошло не так');
          }
        });
      } else{
        this.articlesService.EditArticle(this.Id,form).subscribe({
          next:()=>{
            this.eventsService.success('Статья успешно сохранена');
            this.context.completeWith(true)
          },
          error:()=>{
            this.eventsService.throwError('Что-то пошло не так');
          }
        });
      }

    }


  }

  get data(){
    return this.context.data;
  }





  AddFile(value:any){
    const file = value;
    let size:number = +(file['size'])
    if(size<=5242880) {
      this.file = file;
      this.filePreview = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.file))
    }else{
      this.eventsService.throwError('Размер файла не должен превышать 5Мб!')
    }
  }




}
