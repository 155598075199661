import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum LoadingStates {
  UNKNOWN = 'UNKNOWN',
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  GET_PROMO_LIST = "GET_PROMO_LIST",
  GET_PROMO_ITEM = "GET_PROMO_ITEM",
  DELETE_PROMO = "DELETE_PROMO",
  ADD_PROMO = "ADD_PROMO",
  GET_PROMO_HISTORY = "GET_PROMO_HISTORY",
  GET_PACKETS = "GET_PACKETS",
  GET_CLIENTS = "GET_CLIENTS",
  GET_EXPERTS = "GET_EXPERTS",
  GET_EXPERT_INFO = "GET_EXPERT_INFO",
  GET_CLIENT_INFO = "GET_CLIENT_INFO",
  CHANGE_EXPERT = "CHANGE_EXPERT",
  GET_TARIFFS = "GET_TARIFFS",
  GET_ARTICLES = "GET_ARTICLES",
  NEW_ARTICLE = "NEW_ARTICLE",
  GET_ARTICLE = "GET_ARTICLE"
}

export interface LoadingInterface {
  id: string;
  type: string;
}

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  constructor() {}

  StatesNames = LoadingStates;
  States = Object.keys(LoadingStates).map((x) => {
    return { state: x, value: false };
  });
  Loadings: LoadingInterface[] = [];
  IsLoadingChange$ = new Subject<boolean>();

  addLoading(requestType = LoadingStates.UNKNOWN): string {
    const id = this.uuidv4();
    this.Loadings.push({
      id,
      type: requestType,
    });
    this.States.find((x) => x.state == requestType)!.value = true;
    this.IsLoadingChange$.next(true);
    return id;
  }

  removeLoading(id: string): LoadingInterface[] {
    const type = this.Loadings.find((x) => x.id == id)!.type;
    this.Loadings = this.Loadings.filter((f) => f.id != id);

    let countCurrentType = this.Loadings.filter((x) => x.type == type).length;
    this.States.find((x) => x.state == type)!.value =
      countCurrentType > 0 ? true : false;
    this.IsLoadingChange$.next(true);

    return this.Loadings;
  }

  isHasLoadingByType(requestType: LoadingStates) {
    return this.Loadings.findIndex((x) => x.type == requestType) > -1
      ? true
      : false;
  }

  uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      var r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  getIdFromArrayStateByType(type: LoadingStates) {
    return this.States.findIndex((x) => x.state == type);
  }
}
