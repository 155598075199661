import {Injectable} from "@angular/core";
import {MainService} from "./main.service";
import {LoadingService} from "./loading.service";

@Injectable({
  providedIn: "root",
})

export class ArticlesService {
  constructor(
    private mainService: MainService,
    private LoadingService: LoadingService,
  ) {
  }


  NewArticle(payload:any){
    return this.mainService.SendPost('/articles',payload,this.LoadingService.StatesNames.NEW_ARTICLE)
  }

  EditArticle(id:number,payload:any){
    return this.mainService.SendPatch(`/articles/${id}`,payload,this.LoadingService.StatesNames.NEW_ARTICLE)
  }

  GetArticlesList() {
    return this.mainService.SendGet(`/articles`, this.LoadingService.StatesNames.GET_ARTICLES);
  }

  DeleteArticle(id: number) {
    return this.mainService.SendDelete(`/articles/${id}`, {});
  }
}
