import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class EventsService {
  Loading = false;
  IsLoadingChange$ = new Subject<boolean>();

  Proccessing = false;
  IsProccessingChange$ = new Subject<boolean>();

  Error = {
    status: false,
    text: "",
  };
  IsErrorChange$ = new Subject<boolean>();

  Success = {
    status: false,
    text: "",
  };
  IsSuccessChange$ = new Subject<boolean>();

  constructor() {
    this.resetAllEvents();
  }

  throwError(text: string) {
    //this.resetAllEvents();
    this.Error = {
      status: true,
      text,
    };
    this.IsErrorChange$.next(true);
  }

  success(text: string) {
    //this.resetAllEvents();
    this.Success = {
      status: true,
      text,
    };
    this.IsSuccessChange$.next(true);
  }

  resetAllEvents() {
    this.IsLoadingChange$.next(false);
    this.IsProccessingChange$.next(false);
    this.IsErrorChange$.next(false);
    this.IsSuccessChange$.next(false);

    this.Loading = false;
    this.Proccessing = false;
    this.Error = {
      status: false,
      text: "",
    };
    this.Success = {
      status: false,
      text: "",
    };
  }
}
